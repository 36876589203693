














































































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Pagination extends Vue {
  // props
  @Prop({
    default: () => ({
      price_method: "1",
      value: {
        price_list: {
          price_type: "1",
          price_ratio: "1",
          price_add_sub: "1",
          price_number: "1",
        },
        price_rounding: "1",
        reserve_decimal: 2,
      },
    }),
  })
  value: any;
  @Prop() is_pick!: any;

  priceValidator(rule: any, value: any, callback: any) {
    const price_number = Number(value.price_number);
    const price_ratio = Number(value.price_ratio);
    if (price_number >= 0 && price_ratio >= 0) {
      callback();
    } else {
      return callback(new Error("请正确填写市场价公式"));
    }
  }

  get priceForm(): any {
    return this.value;
  }

  set priceForm(value) {
    this.$emit("update:value", value);
  }
}
