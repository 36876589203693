import request from "@/plugins/axios";

// S 商品管理

// 商品列表
export const cinemaBatchList = (params: any) =>
    request.get("/movie.BasicData/cinemaBatchList", { params });

export const areaLists = (params: any) =>
    request.get("/movie.BasicData/areaLists", { params });
export const showBatchList = (params: any) =>
    request.get("/movie.BasicData/showBatchList", { params });
export const cityList = (params: any) =>
    request.get("/movie.BasicData/cityList", { params });

export const getNation = (params: any) =>
    request.get("/movie.BasicData/getNation", { params });

export const cinemaStatus = (data: any) =>
    request.post("/movie.BasicData/cinemaStatus", data);
export const setCinemaPrice = (data: any) =>
    request.post("/movie.BasicData/setCinemaPrice", data);
export const cinemaPickOut = (data: any) =>
    request.post("/movie.BasicData/cinemaPickOut", data);
export const cityPickOut = (data: any) =>
    request.post("/movie.BasicData/cityPickOut", data);
export const nationPickOut = (data: any) =>
    request.post("/movie.BasicData/nationPickOut", data);
export const setCityPrice = (data: any) =>
    request.post("/movie.BasicData/setCityPrice", data);
export const setNation = (data: any) =>
    request.post("/movie.BasicData/setNation", data);
export const deleteCityPrice = (data: any) =>
    request.post("/movie.BasicData/deleteCityPrice", data);

