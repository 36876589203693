












































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AreaSelect extends Vue {
    /** S Props **/
    @Prop() value: any;
    @Prop() list: any;
    /** E Props **/
    checkAll: boolean = false;
    isIndeterminate: boolean = false;
    /** S Computed **/
    // checkList: any = [];
    get checkList(): any {
        if (this.value.length === 0) {
            this.isIndeterminate = false;
            return [];
        }
        let data = [];
        this.list.map((v) => {
            this.value.map((k) => {
                if (k == v.id) {
                    data.push(v);
                }
            });
        });
        return data;
    }

    set checkList(value) {
        const id = value.map((v) => {
            return v.id;
        });
        this.$emit("input", id);
    }

    /** E Computed **/

    handleCheckAllChange(val) {
        this.isIndeterminate = false;
        if (val) {
            const id = this.list.map((v) => {
                return v.id;
            });
            this.$emit("input", id);
        } else {
            this.$emit("input", []);
        }
    }

    handleCheckedChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.list.length;
        this.isIndeterminate =
            checkedCount > 0 && checkedCount < this.list.length;
    }

    handleDel(index: number) {
        this.checkList.splice(index, 1);
        const id = this.checkList.map((v) => {
            return v.id;
        });
        this.$emit("input", id);
    }
    created() {}
}
